import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "../../Layout/Hero"
import { Mobile } from "../../Elements/Contact"
import Container from "../../Layout/Container"

const HelpCenterBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath:{ eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#cfe0e2", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 800
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const stethoscope = data.stethoscope.childImageSharp.fluid.src

  return(
    <Hero
      background={{ image: stethoscope }}
      className="has-background-cereal-teal mt-1"
    >
      <Container isCentered>
        <p className="has-text-left has-text-black">
          Have other questions about the program? Get answers immediately from
          our{" "}
          <a
            href="/help-center"
            target="_blank"
            className="has-text-weight-bold has-text-primary"
          >
            Help Center
          </a>.
          {/* {" "} */}
          {/* or contact us at <Mobile />. */}
        </p>
      </Container>
    </Hero>
  )
}

export default HelpCenterBanner
