import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Container from "./Container"
import Navbar from "./Navbar"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import SEO from "./SEO"

import styles from "./utils/layout.module.scss"

const Layout = ({ children, title, subtitle, seoTitle, display = { helpCenterBanner: false } }) => {
  return (
    <div className={classNames(styles["main"])}>
      <Fragment>
        <SEO title={seoTitle} />
        <Navbar />
        <Notification isHome />
        {title && (
          <Container isCentered className="pb-3">
            <h2 className="has-text-centered mb-0 has-text-primary mx-2-mobile">
              {title}
            </h2>
            <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-4 mx-2-mobile">
              {subtitle}
            </h4>
          </Container>
        )}
        <main className={classNames(styles["main__content"])}>{children}</main>
        {display.helpCenterBanner && (
          <HelpCenterBanner />
        )}
        <Footer />
      </Fragment>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
