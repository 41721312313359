import React from "react"
import {
  GATSBY_PATIENTLINK_CONTACT_EMAIL,
  GATSBY_CONTACT_NUMBER,
} from "gatsby-env-variables"

const Email = () => {
  return (
    <span>
      <a
        href={`mailto:${GATSBY_PATIENTLINK_CONTACT_EMAIL}`}
        target="_blank"
        rel="noreferrer noopener"
        className="link has-text-primary has-text-weight-bold"
      >
        {GATSBY_PATIENTLINK_CONTACT_EMAIL}
      </a>
    </span>
  )
}

const Mobile = () => {
  return (
    <a className="has-text-weight-bold" href={`tel:${GATSBY_CONTACT_NUMBER}`}>
      {GATSBY_CONTACT_NUMBER}
    </a>
  )
}

const Contact = () => {
  return (
    <span>
      <Email /> or <Mobile />
    </span>
  )
}

export { Email, Mobile, Contact }
