/*
 * MedGrocer logo only
 */
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"

const Navbar = () => {
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long-white.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircle: file(
        relativePath: { eq: "logos/medgrocer-square--white.png" }
      ) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  return (
    <nav className="navbar is-fixed-top is-primary is-flex">
      <div className={classNames("ml-1", styles["navbar__brand"])}>
        <Link to="/">
          <Img fixed={logoLong} className="is-hidden-mobile" />
          <Img fixed={logoCircle} className="is-hidden-tablet" />
        </Link>
        {/* <div className={classNames("ml-1 mr-1 is-size-6")}>
          For inquiries, visit our{" "}
          <Link to="/help-center">
            <span className="has-text-primary has-text-weight-bold">
              help center
            </span>
          </Link>
        </div> */}
      </div>
    </nav>
  )
}

export default Navbar
